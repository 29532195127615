import apiClient from "./client";
import privateAPIClient from "./privateClient";
import { APIUrlStrings } from "./endpoints";
import privateAPIClientMultiForm from "./privateClientMultiForm";
import { create } from "apisauce";
import settings from "./settings";

const GlobalAPI = create({
  baseURL: settings.API_BASE_URL,
});

//login
export const APIAdminLogin = (payload) => {
  return apiClient.post(APIUrlStrings.login, payload);
};

export const APIAdminLogout = (payload, headers) => {
  return privateAPIClient.post(APIUrlStrings.logout, payload, { headers });
};

// Drawer sidebar menu list
export const APISidebarMenuDrawerList = (payload) => {
  return privateAPIClient.get(APIUrlStrings.sideBarMenuList, payload);
};

export const APIgetUserList = () => {
  return privateAPIClient.get(APIUrlStrings.userlist);
};

export const APIUserAdd = (payload) => {
  return privateAPIClient.post(APIUrlStrings.useradd, payload);
};

export const APIUserEdit = (payload) => {
  return privateAPIClient.get(APIUrlStrings.userEdit + "?id=" + payload?.id);
};

export const APIUserUpdate = (payload) => {
  return privateAPIClient.post(APIUrlStrings.userUpdate, payload);
};

export const APIDeleteUserList = (payload) => {
  return privateAPIClient.get(APIUrlStrings.deleteuserlist, payload);
};

export const APIgetRoleList = () => {
  return privateAPIClient.get(APIUrlStrings.roleslist);
};
export const APIgetUserRoleList = () => {
  return privateAPIClient.get(APIUrlStrings.userRoleslist);
};

export const APIgetLeadUserAssignList = () => {
  return privateAPIClient.get(APIUrlStrings.userassignlist);
};

export const APIgetUserAssignList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.getRoles + "?role_id=" + payload?.role_id
  );
};

export const APIUpdateRole = (payload) => {
  return privateAPIClient.post(APIUrlStrings.updateRole, payload);
};

export const APICreateRoles = (payload) => {
  return privateAPIClient.post(APIUrlStrings.createRole, payload);
};

export const APIDeleteRoles = (payload) => {
  return privateAPIClient.post(APIUrlStrings.deleteRole, payload);
};

export const APIEditRole = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editRole + "?role_id=" + payload?.role_id
  );
};

//branch master List;
export const APIBranchMasterList = () => {
  return privateAPIClient.get(APIUrlStrings.branchList);
};
//createBranchList;

export const APICreateBranchMasterList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.createBranchList, payload);
};
// editBranchList
export const APIBranchMasterEdit = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editBranchList + "?id=" + payload?.id
  );
};
// updateBranchList

export const APIUpdateBranchMasterList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.updateBranchList, payload);
};

export const APIgetAgentonBoardList = () => {
  return privateAPIClient.get(APIUrlStrings.agentonboardlist);
};

export const APIgetTeamVisitList = () => {
  return privateAPIClient.get(APIUrlStrings.getTeamvisit);
};

export const APIUpdateChannel = (payload) => {
  return privateAPIClient.post(APIUrlStrings.addupdateChannel, payload);
};

export const APICreateChannel = (payload) => {
  return privateAPIClient.post(APIUrlStrings.addupdateChannel, payload);
};

export const APIDeleteChannel = (payload) => {
  return privateAPIClient.post(APIUrlStrings.deleteChannel, payload);
};

export const APIEditChannel = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editChannel + "?channel_id=" + payload?.channel_id
  );
};

export const APIgetChannelList = () => {
  return privateAPIClient.get(APIUrlStrings.getChannellist);
};

export const APIAddGreetingCategory = (payload) => {
  return privateAPIClient.post(
    APIUrlStrings.addupdateGreetingcategory,
    payload
  );
};

export const APIgetGreetingCategoryList = () => {
  return privateAPIClient.get(APIUrlStrings.getGreetingcategorylist);
};

export const APIEditGreetingCategory = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editGreetingcategory +
      "?greeting_category_id=" +
      payload?.greeting_category_id
  );
};

export const APIAddupdateGreetingMasterlist = (payload) => {
  return privateAPIClient.post(APIUrlStrings.addupdategreetingmaster, payload);
};

export const APIgetGreetingMasterList = () => {
  return privateAPIClient.get(APIUrlStrings.getmastergreetinglist);
};

export const APIEditGreetingMasterlist = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editmastergreeting + "?greeting_id=" + payload?.greeting_id
  );
};

export const APIDeleteGreetingMaster = (payload) => {
  return privateAPIClient.post(APIUrlStrings.deletemastergreeting, payload);
};

export const APIGreetingMasterType = (payload) => {
  return privateAPIClient.get(APIUrlStrings.getgreetingmastertype, payload);
};

// LeadList

export const APIgetLeadList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.leadList + "?status=" + payload?.status
    // +"&lead_status=" +
    // (payload?.lead_status)
  );
};
export const APIReassignLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.reassignLead, payload);
};
export const APILeadDetails = (payload) => {
  return privateAPIClient.get(APIUrlStrings.leadDetails + "?id=" + payload?.id);
};

export const APIgetLeadSDetails = () => {
  return privateAPIClient.get(APIUrlStrings.leadList);
};

export const APIgetLeadSearchList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.leadList + "?search=" + payload?.search
  );
};

export const APIgetLeadSListDateFilter = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.leadList +
      "?from_date=" +
      (payload?.from_date || "") +
      "&to_date=" +
      (payload?.to_date || "") +
      "&status=" +
      (payload?.status || "") +
      "&search=" +
      (payload?.search || "") +
      "&lead_status=" +
      (payload?.lead_status || "") +
      "&lead_type=" +
      (payload?.lead_type || "")
  );
};

// "?per_page=" + (payload?.per_page || 30) +
// "&page=" + (payload?.page || 0) +
// "&search_text=" + (payload?.search_text || "")

// };

export const APIgetLeadReassignUser = (payload) => {
  return privateAPIClient.post(APIUrlStrings.reassignLeadUser, payload);
};

export const APILeadStatuslist = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.leadStatusList + "?status=" + payload?.status
  );
};

export const APILeadStatusDetails = () => {
  return privateAPIClient.get(APIUrlStrings.leadStatusList);
};

export const APILeadDelete = (payload) => {
  return privateAPIClient.get(APIUrlStrings.leadDelete + "?id=" + payload?.id);
};

export const APIgetSalesExecutiveUsersList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.salesExecutiveUsers + "?role_name=" + payload?.role_name
  );
};

// export const APIgetVehicleTypeList = () => {
//   return privateAPIClient.post(APIUrlStrings.vehicleTypes);
// };

export const APIgetFuelTypeList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.vehicleTypes, payload);
};

// export const APIgetModelTypeList = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.vehicleTypes, payload);
// };

// export const APIgetVariantTypeList = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.vehicleTypes, payload);
// };

// export const APIgetColorList = (payload) => {
//   return privateAPIClient.post(APIUrlStrings.vehicleTypes, payload);
// };

export const APIDownloadLeadReport = () => {
  return privateAPIClient.post(APIUrlStrings.downloadLeadData);
};

export const APIReassignBackLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.ReassignBack, payload);
};

export const APIUpdateStatusLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.updateStatusLead, payload);
};

export const APICreateLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.createLead, payload);
};

export const APIEditLead = (payload) => {
  return privateAPIClient.get(APIUrlStrings.EditLead + "?id=" + payload?.id);
};

export const APIUpdateLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.UpdateLead, payload);
};

export const APILeadSource = () => {
  return privateAPIClient.get(APIUrlStrings.leadSource);
};

export const APILeadType = () => {
  return privateAPIClient.get(APIUrlStrings.leadTypes);
};

export const APIDownloadLeadSimple = (payload) => {
  return privateAPIClient.post(APIUrlStrings.DownloadLeadSampleFile, payload);
};

export const APIUploadLeads = (payload) => {
  return privateAPIClientMultiForm.post(APIUrlStrings.UploadLeads, payload);
};

export const APIBookServiceLead = (payload) => {
  return privateAPIClient.post(APIUrlStrings.updateBookService, payload);
};
//Assign Leads
export const APIAssignLeads = (payload) => {
  return privateAPIClient.post(APIUrlStrings.assignLeads, payload);
};

//location list
export const APIgetLocationList = () => {
  return privateAPIClient.get(APIUrlStrings.locationList);
};
//getStateList;
export const APIgetLocationStateList = () => {
  return privateAPIClient.get(APIUrlStrings.getStateList);
};
//post state by city list
export const APIgetLocationStateByCityList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.getStateByCity, payload);
};

export const APIgetAddLocation = (payload) => {
  return privateAPIClient.post(APIUrlStrings.addLocation, payload);
};

export const APIgetEditLocation = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editLocation + "?location_id=" + payload?.location_id
  );
};

export const APIgetUpdateLocation = (payload) => {
  return privateAPIClient.post(APIUrlStrings.updateLocation, payload);
};

// dealerList
export const APIgetDealerList = () => {
  return privateAPIClient.get(APIUrlStrings.dealerList);
};

// subscription
export const APIgetDealerSubscription = () => {
  return privateAPIClient.get(APIUrlStrings.getSubcriptionPlan);
};

//addUpdateDealer
export const APIUpdateDealerList = (payload) => {
  return privateAPIClient.post(APIUrlStrings, payload);
};

// GlobalAPI
export const APIAddUpdateDealerListGlobal = (payload) => {
  return GlobalAPI.post(APIUrlStrings.addUpdateDealer, payload);
};

export const APIAddDealerListGlobal = (payload) => {
  return GlobalAPI.post(APIUrlStrings.AddDealer, payload);
};

export const APIDealerPincodeGlobal = (payload) => {
  return GlobalAPI.post(APIUrlStrings.pincodeDealer, payload);
};

export const APIDealerTypeGlobal = () => {
  return GlobalAPI.get(APIUrlStrings.DealerType);
};

export const APIAddUpdateDealerList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.addUpdateDealer, payload);
};

export const APIAddDealerList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.AddDealer, payload);
};

export const APIDealerPincode = (payload) => {
  return privateAPIClient.post(APIUrlStrings.pincodeDealer, payload);
};

export const APIDealerType = () => {
  return privateAPIClient.get(APIUrlStrings.DealerType);
};

export const APIDashboardData = (payload) => {
  return privateAPIClient.post(APIUrlStrings.dashboardData, payload);
};

export const APIFilterOEMDashboard = (payload) => {
  return privateAPIClient.post(APIUrlStrings.filterOEMdashboardData, payload);
};

export const APIDealerCreatePayment = (payload) => {
  return privateAPIClient.post(APIUrlStrings.CreateRazerpay, payload);
};

export const APIDealerSuccessPayment = (payload) => {
  return privateAPIClient.post(APIUrlStrings.SuccessRazerpay, payload);
};

//click to call
export const APIClickToCall = (payload) => {
  return privateAPIClient.post(APIUrlStrings.clickToCall, payload);
};

// editDealer
export const APIEditDealer = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.EditDealer + "?dealer_id=" + payload?.dealer_id
  );
};

// product list
export const APIgetProductList = () => {
  return privateAPIClient.get(APIUrlStrings.productList);
};
// add product
export const APIAddProductList = (payload) => {
  return privateAPIClient.post(APIUrlStrings.productAdd, payload);
};

// productEdit;
export const APIProductEdit = (payload) => {
  return privateAPIClient.get(APIUrlStrings.productEdit + "?id=" + payload?.id);
};

// subscription plan
export const APIgetSubscriptionPlanList = () => {
  return privateAPIClient.get(APIUrlStrings.subscriptionPlan);
};

// add subscription
export const APICreateUpdateSubscriptionPlan = (payload) => {
  return privateAPIClient.post(
    APIUrlStrings.createUpdateSubscriptionPlan,
    payload
  );
};

//edit subscription plan
export const APISubscriptionPlanEdit = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editSubscriptionPlan +
      "?subscription_plan_id=" +
      payload?.subscription_plan_id
  );
};

// subscription benefit list
export const APIgetSubscriptionBenefitist = () => {
  return privateAPIClient.get(APIUrlStrings.subscriptionBenefitList);
};
// subscription create update benefit
export const APICreateUpdateSubscriptionBenefit = (payload) => {
  return privateAPIClient.post(
    APIUrlStrings.createUpdateSubscriptionBenefit,
    payload
  );
};

// edit Subscription benefit
export const APISubscriptionBenefitEdit = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editSubscriptionBenefit +
      "?subscription_benefit_id=" +
      payload?.subscription_benefit_id
  );
};

// Subscription Benefit Mapping
export const APIgetSubscriptionMappingList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.SubscriptionBenefitMapping,
    payload
  );
};

// creat update subscriptionbenefit mapping
export const APICreateUpdateSubscriptionBenefitMapping = (payload) => {
  return privateAPIClient.post(
    APIUrlStrings.createUpdateSubscriptionBenefitMapping,
    payload
  );
};

//subscription plan b enefit seletionList
export const APIgetSubscriptionPlanBenefitSelectionList = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.subscriptionPlanBenefitSeletionList,
    payload
  );
};

//editSubscriptionBenefitMapping
export const APISubscriptionBenefitMappingEdit = (payload) => {
  return privateAPIClient.get(
    APIUrlStrings.editSubscriptionBenefitMapping +
      "?subscription_benefit_mapping_id=" +
      payload?.subscription_benefit_mapping_id
  );
};

//campaignRegister
//without token
export const APIcampaignRegister = (payload) => {
  return GlobalAPI.post(APIUrlStrings.campaignRegister, payload);
};
//without token
export const APIcampaignFeedback = (payload) => {
  return GlobalAPI.post(APIUrlStrings.campaignFeedback, payload);
};

//without token
export const APIcampaignList = () => {
  return GlobalAPI.get(APIUrlStrings.campaignList);
};

export const APIcampaignLists = () => {
  return privateAPIClient.get(APIUrlStrings.campaignList);
};

//without token
export const APIcampaignFeedbackList = () => {
  return GlobalAPI.get(APIUrlStrings.campaignFeedbackList);
};

export const APIcampaignMasterList = () => {
  return GlobalAPI.get(APIUrlStrings.campaignMasterList);
};

// export const APIEditGreetingMasterlist = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.editmastergreeting + "?greeting_id=" + payload?.greeting_id
//   );
// };

// export const APIgetPermissionsList = (payload) => {
//   return privateAPIClient.get(
//     APIUrlStrings.permissions +
//       "?per_page=" +
//       (payload?.per_page || 30) +
//       "&page=" +
//       (payload?.page || 0) +
//       "&search_text=" +
//       (payload?.search_text || "")
//   );
// };
