import React, { useEffect } from "react";

const CustomLoader = ({ children, showLoader }) => {
  // Effect to handle scrolling behavior
  useEffect(() => {
    document.body.style.overflow = showLoader ? "hidden" : "scroll";

    // Cleanup function
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showLoader]);

  return (
    <>
      {showLoader && (
        <div style={styles.container}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust opacity or remove for no background
    width: "100%",
    height: "100%",
    zIndex: 99999,
    justifyContent: "center",
    alignItems: "center",
  },
};

export default CustomLoader;
