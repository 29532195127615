// useAuth.js

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserAuth } from "../../../redux/reducers/userAuthSlice";

const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticatedLocalStorage =
    localStorage.getItem("isAuthenticated") === "true";
  const roleLocalStorage = localStorage.getItem("Roles");
  const roleidLocalStorage = localStorage.getItem("Roles_id");
  const Name = localStorage.getItem("Name");

  useEffect(() => {
    if (isAuthenticatedLocalStorage || roleLocalStorage || Name) {
      dispatch(
        setUserAuth({
          isAuthenticated: isAuthenticatedLocalStorage,
          role: roleLocalStorage,
          role_id:roleidLocalStorage,
          name:Name
        })
      );
    }
  }, [Name, dispatch, isAuthenticatedLocalStorage, roleLocalStorage, roleidLocalStorage]);

  return {
    isAuthenticated: isAuthenticatedLocalStorage,
    role: roleLocalStorage,
    role_id:roleidLocalStorage,
    name: Name

  };
};

export default useAuth;
