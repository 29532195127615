import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearUserAuth } from "../../../redux/reducers/userAuthSlice";
import { useNavigate } from "react-router-dom";
import ROUTES_NAVIGATION from "../../../routes/routes";
import { APIAdminLogout } from "../../../api/common";
import Cookies from "universal-cookie";
import logout from "../../../assets/logout_tvs.png";

function HeaderComponent() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.userAuthSlice.isAuthenticated
  );
  const Role = useSelector((state) => state.userAuthSlice.name);

  const dispatch = useDispatch();
  console.log("header value===>", Role);

  const cookies = new Cookies();

  const handleLogout = async () => {
    const authToken = cookies.get("auth_token");
    if (authToken) {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      try {
        const response = await APIAdminLogout({}, headers);
        console.log(response);
        dispatch(clearUserAuth());
        cookies.remove("auth_token");
        navigate(ROUTES_NAVIGATION.LOGIN);
      } catch (error) {
        console.log(error);
        alert("Error occurred during logout:", error);
      }
    } else {
      alert("No auth token found in cookies.");
    }
  };

  useEffect(() => {
    const toggleSidebar = () => {
      const sidebar = document.getElementsByClassName("js-sidebar")[0];
      const toggleButton =
        document.getElementsByClassName("js-sidebar-toggle")[0];

      if (sidebar && toggleButton) {
        sidebar.classList.toggle("collapsed", isCollapsed);
        toggleButton.classList.toggle("collapsed", isCollapsed);

        // Optionally, dispatch a resize event after the transition ends
        sidebar.addEventListener("transitionend", () => {
          window.dispatchEvent(new Event("resize"));
        });
      }
    };

    toggleSidebar(); // Call initially to ensure proper initialization

    // Clean up event listeners when the component unmounts
    return () => {
      const sidebar = document.getElementsByClassName("js-sidebar")[0];
      if (sidebar) {
        sidebar.removeEventListener("transitionend", () => {
          window.dispatchEvent(new Event("resize"));
        });
      }
    };
  }, [isCollapsed]);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light navbar-bg">
        <a
          className="sidebar-toggle js-sidebar-toggle"
          onClick={handleToggleSidebar}
        >
          <i className="hamburger align-self-center"></i>
        </a>
        <div className="col-md-4">
          {/* <div className="input-group input-group-lg has-validation">
            <span className="input-group-text" id="inputGroupPrepend">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Property..."
              required
            />
          </div> */}
        </div>
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle"
                href="#"
                id="alertsDropdown"
                data-bs-toggle="dropdown"
              >
                <div className="position-relative">
                  <FontAwesomeIcon icon={faBell} />
                  <span className="indicator">4</span>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                aria-labelledby="alertsDropdown"
              >
                <div className="dropdown-menu-header">4 New Notifications</div>
                <div className="list-group">
                  <a href="#" className="list-group-item">
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <i
                          className="text-danger"
                          data-feather="alert-circle"
                        ></i>
                      </div>
                      <div className="col-10">
                        <div className="text-dark">Update completed</div>
                        <div className="text-muted small mt-1">
                          Restart server 12 to complete the update.
                        </div>
                        <div className="text-muted small mt-1">30m ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="list-group-item">
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                      <div className="col-10">
                        <div className="text-dark">Lorem ipsum</div>
                        <div className="text-muted small mt-1">
                          Aliquam ex eros, imperdiet vulputate hendrerit et.
                        </div>
                        <div className="text-muted small mt-1">2h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="list-group-item">
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                      <div className="col-10">
                        <div className="text-dark">Login from 192.186.1.8</div>
                        <div className="text-muted small mt-1">5h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="list-group-item">
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                      <div className="col-10">
                        <div className="text-dark">New connection</div>
                        <div className="text-muted small mt-1">
                          Christina accepted your request.
                        </div>
                        <div className="text-muted small mt-1">14h ago</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="dropdown-menu-footer">
                  <a href="#" className="text-muted">
                    Show all notifications
                  </a>
                </div>
              </div>
            </li> */}
            <li className="nav-item text-white">
              {isAuthenticated ? (
                <>
                  <p className="mt-2 ms-3"> Welcome, {Role} </p>
                </>
              ) : (
                <p className="mt-2 ms-3"> Welcome, ??? </p>
              )}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-icon nav-link dropdown-toggle d-none d-sm-inline-block"
                // href="#"
                // data-bs-toggle="dropdown"
              >
                <span className="text-dark" onClick={handleLogout}>
                  <img src={logout} alt="logout" width="35px" />
                </span>
                {/* <span className="text-dark ">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    height={300}
                    className="text-white"
                  />
                </span> */}
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <a className="dropdown-item" href="pages-profile.html">
                  <i className="align-middle me-1" data-feather="user"></i>{" "}
                  Profile
                </a> */}
                {/* <div className="dropdown-divider"></div> */}
                <a className="dropdown-item" onClick={handleLogout}>
                  Log out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default HeaderComponent;
