const ROUTES_NAVIGATION = {
  DASHBOARD: "/",
  LOGIN: "/login",
  RAGISTER: "/ragister",
  RAGISTER_LIST: "/ragister_list",
  FEEDBACK_LIST: "/feedback_list",
  CAMPAIGN_LIST: "/campaign",
  USER_LIST: "/user_list",
  ROLELIST: "/roles",
  LEADLISTING: "/lead_listing",
  LEADDETAILS: "/lead_details",
  UPLOAD_DATA: "/upload_data",
  APPOINTMENT: "/appointment",
  ROLE_LIST: "/role-list",
  GREETING_CATEGORY_LIST: "/greeting-category-list",
  GREETING: "/greeting",
  LOCATION: "/location",
  CHANNEL: "/channel",
  BRANCH_MASTER: "/branch-master",
  AGENTONBOARD: "/agent_onboard",
  AGENTLIST: "/agent_list",
  MEETINGLIST: "/meeting_list",
  DEALER_LIST: "/dealer_list",

  RESET_PASSWORD: "/reset_password",
  PRODUCTS: "/products",
  CATEGORY: "/category",
  ADD_CATEGORY: "/add_category",
  ADD_DEALER: "/add_dealer",
  Edit_DEALER: "/edit_dealer",
  DEALER_SHIPPING: "/dealer_shipping",
  DEALER_PRODUCT: "/dealer_product",
  Edit_DEALER_PRODUCT: "/Edit_dealer_product",
  SUBSCRIPTION_PLAN: "/subscription_plan",
  SUBSCRIPTION_BENEFIT: "/subscription_benefit",
  SUBSCRIPTION_MAPPING: "/subscription_Mapping",


  DEALER_ADD_PAGE: "/dealers/add",
  DEALER_SHIPPING_PAGE: "/dealers/shipping",
  DEALER_PRODUCT_PAGE: "/dealers/product",

  // BRANDS: "/brands",
  // PROFILE: "/profile",
  // ADD_BRANDS: "/add_brands",
  // COLLECTIONS: "/collection",
  // ADD_COLLECTIONS: "/add_collection",
  // OPTION_SET: "/option_set",
  // REVIEWS: "/rewiews",
  // LINKED_PRODUCTS: "/linked_products",
  // PRICE_OVERRIDES: "/price_overrides",
  // PRODUCT_ENQUIRIES: "/product_enquiries",
  // PRODUCT_SUBSCRIPTIONS: "/product_subscriptions",
  // MARKETING_TOOLS: "/marketing_tools",
  // DISCOUNTS: "/discounts",
  // COUPON_CODES: "/coupon_codes",
  // ADD_COUPON_CODES: "/add_coupon_codes",
  // MAILING_LISTS: "/mailing_lists",
  // ORDERS: "/orders",
  // ABANDONED_CHECKOUTS: "/abandoned_checkout",
  // SHIPING_NOW: "/shiping_now",
  // SHIPMENTS: "/shipments",
  // RETURNS: "/returns",
  // REFUNDS: "/refunds",
  // TRANSACTIONS: "/transactions",
  // USERS_SUBSCRIPTIONS: "/users_subcriptions",
  // USERS: "/users",
  // CUSTOMER: "/customer",
  // USERS_ROLE_PERMISSION: "/users_role_permission",
  // USERS_ROLES: "/user_roles",
  // ADD_USERS: "/add_users",
  // PERMISSION: "/permission",
  // USER_GROUPS: "/user_groups",
  // USER_DETAILS: "/user_details",
  // ENQUIRIES: "/enquiries",
  // WALLET_TRANSACTIONS: "/wallet_transactions",
  // SLIDES: "/slides",
  // NAVIGATION: "/navigation",
  // BANNERS: "/banners",
  // ADD_BANNERS: "/add_banners",
  // BANNERS_TYPE: "/banners_type",
  // ADD_BANNERS_TYPE: "/add_banners_type",
  // PAGES: "/pages",
  // FILES: "/files",
  // FORMS: "/forms",
  // BLOGS: "/blogs",
  // BLOGS_POSTS: "/blog_posts",
  // BLOGS_COMMENTS: "/blog_comments",
  // ALL_SETTINGS: "/all_settings",
  // GENERAL_SETTINGS: "/general_settings",
  // STORE_LOCATIONS: "/store_locations",
  // SETTINGS: "/settings",
  // PAYMENT_SETTINGS: "/payment_settings",
  // PAYMENY_METHODS: "/payment_methods",
  // INVOICE_SETTING: "/invoice_settings",
  // SHIPPING_SETTINGS: "/shipping_settings",
  // CONFIGRATION_SETTINGS: "/configration_settings",
  // ADD_PRODUCT: "/add_product",
  // PINCODE: "/pincode",
  // ADD_PINCODE: "/add_pincode",
  // STORE: "/store",
  // MAIN_STORE: "/main_store",
  // SUB_STORE: "/sub_store",
  // ADD_SUB_STORE: "/add_substore",
  // STATE_CITY_AREA: "/state_city_area",
  // TIME_SLOT: "/time_slot",
  // DELIVERY_TYPE: "/delivery_type",
  // DELIVERY_TYPE_MASTER: "/delivery_type_master",
  // VARIENTS_TYPE: "/varient_type",
  // CANCELLED_ORDER: "/cancelled_order",
  // ADD_ORDER: "/add_order",
  // ORDER_DETAILS: "/order_details",
  // ADD_DELIVERY: "/add_delivery",
  // INVENTORY: "/inventory",
  // TAX_RULE: "/tax_rule",
  // PAYMENT_METHODS: "/payment_methods",
  // SHIPPMENT: "/shippment",
  // ADD_TAX_RULE: "/add_tax_rule",
  // ADD_PAYMENT_METHOD: "/add_payment_method",
  // ADD_SHIPPMENT: "/add_shippment",
  // INVOICE_VIEW: "/invoice",
  // EMAIL_LOGS: "/email_logs",
  // SMS_LOGS: "/sms_logs",
  // IMPORT_LOGS: "/import_logs",
  // EXPORT_LOGS: "/export_logs",
  // AUDIT_LOGS: "/audit_logs",
  // ERROR_LOGS: "/error_logs",
};

export default ROUTES_NAVIGATION;
