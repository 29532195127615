import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../../shared/CustomInput";
import {
  APIAdminLogin,
  APIcampaignMasterList,
  APIcampaignRegister,
} from "../../../api/common";
import Cookies from "universal-cookie";
import CustomLoader from "../../shared/CustomLoader";
import SweetAlert2 from "react-sweetalert2";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../../assets/DigishiftLogo.png";
import quantiqueLogo from "../../../assets/quantique_logo.png";

function Ragister() {
  const [showLoader, setShowLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [Data, setData] = useState([]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [alertOptions, setAlertOptions] = useState({});

  const handleRagisterSubmit = useCallback(
    async (data) => {
      setShowLoader(true); // Show loader when submitting login
      const { dealer_name, email, mobile_no, name } = data;
      const payload = {
        dealer_name: dealer_name || "",
        name: name || "",
        email: email || "",
        mobile_no: mobile_no || "",
        agree: isChecked || "",
      };
      try {
        const response = await APIcampaignRegister(payload);
        
        if (response?.ok && response?.data?.status) {
          toast.success(response?.data?.message);
          //   setAlertOptions({
          //     show: true,
          //     title: "success",
          //     text: response?.data?.message,
          //     icon: "success",
          //   });
        } else {
          toast.error(response?.data?.message);
          //   setAlertOptions({
          //     show: true,
          //     title: "Oops...",
          //     text: response?.data?.message,
          //     icon: "error",
          //   });
        }
      } catch (error) {
        console.error("Error occurred during login:", error);
        // alert("Error occurred during login. Please try again.");
        toast.error("Error occurred during registration. Please try again.");
      } finally {
        setShowLoader(false); // Hide loader after login attempt
      }
    },
    [isChecked]
  );

  // Custom validation messages
  const validationMessages = {
    required: "This field is required",
    maxLength: "Your input exceeds maximum length",
  };

  const errorMessage = (message) => {
    return <div className="text-danger">{message}</div>;
  };

  const getAPIRagisterListHandler = useCallback(async () => {
    setShowLoader(true);
    const resp = await APIcampaignMasterList();
    setShowLoader(false);
    if (resp?.ok && resp?.data) {
      if (resp?.data?.data?.length) {
        setData(resp?.data?.data || []);
      } else {
        setData(resp?.data?.data || []);
      }
    } else {
      setData([]);
    }
  }, []);

  useEffect(() => {
    getAPIRagisterListHandler();
  }, [getAPIRagisterListHandler]);

  return (
    <>

      <ToastContainer />

      <CustomLoader showLoader={showLoader}>
        <main className="d-flex bg-light">
          <div className="container d-flex flex-column">
            <div className="row">
              <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="text-center mt-4">
                    <img src={logo} alt="Logo" width="180px" />
                    <h1 className="h2 mt-2">
                      Welcome To Digishift {Data?.campaign_name}
                    </h1>
                    {/* <p className="lead">
                      <small>
                        {Data?.c_register_start_date} TO{" "}
                        {Data?.c_register_end_date}
                      </small>
                    </p> */}
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="m-sm-3">
                        <form onSubmit={handleSubmit(handleRagisterSubmit)}>
                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Dealer Name"
                              placeholder="Enter Dealer Name"
                              {...register("dealer_name", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.Dealer_name &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Name"
                              placeholder="Enter Name"
                              {...register("name", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.name &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Mobile"
                              placeholder="Enter Mobile No"
                              {...register("mobile_no", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.mobile_no &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="">
                            <CustomInput
                              type="text"
                              label="Email"
                              placeholder="Enter Email"
                              {...register("email", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.email &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div>
                            <div className="form-check align-items-center">
                              <input
                                id="customControlInline"
                                type="checkbox"
                                className="form-check-input"
                                value="remember-me"
                                name="remember-me"
                                {...register("agree", {
                                  required: validationMessages.required,
                                })}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label text-small"
                                htmlFor="customControlInline"
                              >
                                Yes, I acccept the Digishift Campaign Terms and
                                Privacy Policy.
                              </label>
                            </div>
                          </div>

                          <div className="d-grid gap-2 mt-3">
                            <button
                              className="btn btn-lg btn-warning text-black fw-bold"
                              disabled={!isChecked}
                            >
                              Register
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="justify-content-center mt-2">
                    <h4 className="text-center">Powered by</h4>
                    <h5 className="text-center">
                      <img src={quantiqueLogo} alt="Logo" width="50px" />
                    </h5>
                    <div style={{ fontSize: 11 }} className="text-center">
                      Copyright © 2024 QUANTIQUE METADATA PRIVATE LIMITED
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </CustomLoader>
    </>
  );
}

export default Ragister;
