import React, { forwardRef } from "react";

const CustomInput = forwardRef(
  (
    {
      type,
      placeholder,
      value,
      onChange,
      onBlur,
      name,
      label,
      readonly,
      disabled,
      hideLabel,
      labelSize,
      min,
      is_man = false,
    },
    ref
  ) => {
    const labelStyle = {
      fontSize: labelSize || "14px", // Default font size is 14px, change it as needed
    };

    const handleInputChange = (event) => {
      const inputValue = event.target.value;
      if (type === "tel" && inputValue.length > 10) {
        return;
      }
      onChange(event);
    };

    return (
      <div>
        {!hideLabel && (
          <label htmlFor={name} className="form-label my-2" style={labelStyle}>
            {label}<span style={{color:'red'}}>{is_man ? '*' : ''}</span>
          </label>
        )}
        <input
          type={type}
          className="form-control form-control-lg"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onBlur={onBlur}
          readOnly={readonly}
          disabled={disabled}
          name={name}
          min={min}
          ref={ref} // Forward the ref
        />
      </div>
    );
  }
);

export default CustomInput;
