import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import logo from "../../../assets/quant_logo.png";
import logo from "../../../assets/DigishiftLogo.png";

function SidebarComponent() {
  const navigate = useNavigate();
  const location = useLocation();

  const storedMenu = JSON.parse(localStorage.getItem("Menu"));

  return (
    <>
      <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="#">
            <span className="align-middle">
              <img
                src={logo}
                alt="logo"
                style={{ width: "120px", margin: "25px 0px 25px -47px" }}
              />
            </span>
          </a>

          <ul className="sidebar-nav">
            {storedMenu.map((item) => (
              <li
                key={item.id}
                className={`sidebar-item ${
                  location.pathname === item.url ? "active" : ""
                }`}
              >
                <a
                  className="sidebar-link"
                  onClick={() => {
                    navigate(item.url);
                  }}
                >
                  <i className={item.menu_icon}></i>
                  <span className="align-middle">{item.menu_name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default SidebarComponent;
