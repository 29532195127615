import React, { useState, useEffect, useCallback } from "react";
import {APIcampaignList } from "../../../api/common";
import CustomLoader from "../../shared/CustomLoader";
import RagisterTable from "./RagisterTable";

const RagisterList = () => {
    
  const [userListData, setUserListData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const getAPIUserListHandler = useCallback(async () => {
    setShowLoader(true);
    const resp = await APIcampaignList();
    setShowLoader(false);
    if (resp?.ok && resp?.data) {
      if (resp?.data?.data?.length) {
        setUserListData(resp?.data?.data || []);
      } else {
        setUserListData(resp?.data?.data || []);
      }
    } else {
      setUserListData([]);
    }
  }, []);

  useEffect(() => {
    getAPIUserListHandler();
  }, [getAPIUserListHandler]);

  return (
    <>
      <CustomLoader showLoader={showLoader}>
        <div className="p-5">
          <div className="d-flex justify-content-between ">
            <div className="mb-1">
              <h3 className="fw-bold">Registration List</h3>
            </div>
          </div>

          <RagisterTable
            userList={userListData}
          />
        </div>
      </CustomLoader>
    </>
  );
};


export default RagisterList