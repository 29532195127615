// userAuthSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  role: null,
  role_id:null,
  name: null,
};

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setUserAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.role = action.payload.role;
      state.role_id = action.payload.role_id;
      state.name = action.payload.name;

      // Update localStorage
      localStorage.setItem("isAuthenticated", action.payload.isAuthenticated);
      localStorage.setItem("Roles", action.payload.role);
      localStorage.setItem("Roles_id", action.payload.role_id);
    },

    clearUserAuth: (state) => {
      state.isAuthenticated = false;
      state.role = null;
      state.name = null;
      
      // Clear localStorage
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("Roles");
      localStorage.removeItem("Roles_id");
      localStorage.removeItem("Name");
      localStorage.removeItem("Menu");
    },
  },
});

export const { setUserAuth, clearUserAuth } = userAuthSlice.actions;

export default userAuthSlice.reducer;
