import React from "react";
import ROUTES_NAVIGATION from "../../../routes/routes";
import { useNavigate } from "react-router-dom";


const NotFoundPage = () => {

    const navigate = useNavigate();

    const Home = () => {
        navigate(ROUTES_NAVIGATION.DASHBOARD);
      };

  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <button className="btn btn-primary" onClick={Home()}>Back To Home</button>
    </div>
  );
};

export default NotFoundPage;
