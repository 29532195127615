import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { store } from "./redux";
import RouteComponent from "./routes";
import DealerComponent from "./routes/DealerRoutes";
import Ragister from "./components/pages/RagisterComponent";
import Feedback from "./components/pages/FeedbackComponent";
import RagisterList from "./components/pages/RagisterComponent/RagisterList";
// import Feedbacklist from "./components/pages/FeedbackComponent/FeedbackList";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/*" element={<MainComponent />} />
          <Route path="/register" element={<Ragister />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/register-list" element={<RagisterList />} />
          {/* <Route path="/feedback-list" element={<Feedbacklist />} /> */}
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}

const MainComponent = () => {
  const location = useLocation();
  const isDealerPath = location.pathname.startsWith("/dealers");
  return isDealerPath ? <DealerComponent /> : <RouteComponent />;
};

export default App;
