import React, { useCallback, useState } from "react";
import CustomInput from "../../shared/CustomInput";
import { APIcampaignFeedback } from "../../../api/common";
import CustomLoader from "../../shared/CustomLoader";
import SweetAlert2 from "react-sweetalert2";
import { useForm } from "react-hook-form";
import logo from "../../../assets/DigishiftLogo.png";
import quantiqueLogo from "../../../assets/quantique_logo.png";

function Feedback() {
  const [showLoader, setShowLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [alertOptions, setAlertOptions] = useState({});

  const handleFeedbackSubmit = useCallback(async (data) => {
    setShowLoader(true); // Show loader when submitting login
    const { dealer_name, mobile_no, name, comment } = data;
    const payload = {
      dealer_name: dealer_name || "",
      name: name || "",
      mobile_no: mobile_no || "",
      comment: comment || "",
    };
    try {
      const response = await APIcampaignFeedback(payload);
      if (response?.status && response?.data) {
        setAlertOptions({
          show: true,
          title: "success",
          text: response?.data?.message,
          icon: "success",
        });
      } else {
        setAlertOptions({
          show: true,
          title: "Oops...",
          text: response?.data?.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      alert("Error occurred during login. Please try again.");
    } finally {
      setShowLoader(false); // Hide loader after login attempt
    }
  }, []);

  // Custom validation messages
  const validationMessages = {
    required: "This field is required",
    maxLength: "Your input exceeds maximum 200 characters",
    minLength: "Your input is below minimum 10 characters",
  };

  const errorMessage = (message) => {
    return <div className="text-danger">{message}</div>;
  };

  return (
    <>
      <SweetAlert2
        {...alertOptions}
        onConfirm={() => {
          setAlertOptions({ ...alertOptions, show: false });
          if (alertOptions.onConfirm) alertOptions.onConfirm();
        }}
      />

      <CustomLoader showLoader={showLoader}>
        <main className="d-flex bg-light">
          <div className="container d-flex flex-column">
            <div className="row">
              <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="text-center mt-4">
                    <img src={logo} alt="Logo" width="180px" />
                    <h1 className="h2 mt-5">Welcome To DIGIShift Campaign</h1>
                    {/* <p className="lead">
                      <small>21-07-2024 TO 25-07-2024</small>
                    </p> */}
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <div className="m-sm-3">
                        <form onSubmit={handleSubmit(handleFeedbackSubmit)}>
                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Dealer Name"
                              placeholder="Enter Dealer Name"
                              {...register("dealer_name", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.dealer_name &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Name"
                              placeholder="Enter Name"
                              {...register("name", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.name &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="mb-1">
                            <CustomInput
                              type="text"
                              label="Mobile"
                              placeholder="Enter Mobile No"
                              {...register("mobile_no", {
                                required: validationMessages.required,
                              })}
                            />
                            {errors.mobile_no &&
                              errorMessage(validationMessages.required)}
                          </div>

                          <div className="">
                            <CustomInput
                              type="text"
                              label="Comment"
                              placeholder="Enter Comment"
                              {...register("comment", {
                                required: validationMessages.required,
                                minLength: {
                                  value: 10,
                                  message: validationMessages.minLength,
                                },
                                maxLength: {
                                  value: 200,
                                  message: validationMessages.maxLength,
                                },
                              })}
                            />

                            {errors.comment &&
                              errorMessage(errors.comment.message)}
                          </div>

                          <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-lg btn-warning text-black fw-bold">
                              Feedback
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="justify-content-center mt-2">
                    <h4 className="text-center">Powered by</h4>
                    <h5 className="text-center">
                      <img src={quantiqueLogo} alt="Logo" width="50px" />
                    </h5>
                    <div style={{ fontSize: 11 }} className="text-center">
                      Copyright © 2024 QUANTIQUE METADATA PRIVATE LIMITED
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </CustomLoader>
    </>
  );
}

export default Feedback;
