import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../../components/pages/HeaderComponent";
import SidebarScreen from "../../pages/SidebarComponent";
import FooterComponent from "../../components/pages/FooterComponent";

const MainLayout = () => {
  return (
    <>
      <div className="wrapper">
        <SidebarScreen />
        <div className="main">
          <HeaderComponent />
          <Outlet />
          {/* <FooterComponent /> */}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
