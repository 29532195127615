import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Loadable from "../components/shared/Loadable";
import MainLayout from "../layout/MainLayout";
import ROUTES_NAVIGATION from "./routes";
import useAuth from "../components/shared/CustomAuth";
import NotFoundPage from "../components/pages/NotFoundComponent";

const DashboardDefault = Loadable(
  React.lazy(() => import("../pages/DashboardScreen"))
);

const AdminDashboard = Loadable(
  React.lazy(() => import("../pages/AdminDashboardScreen"))
);

const SuperAdminDashboard = Loadable(
  React.lazy(() => import("../pages/SuperAdminDashboardScreen"))
);

const ManagerDashboard = Loadable(
  React.lazy(() => import("../pages/ManagerDashboardScreen"))
);

const TelecollerDashboard = Loadable(
  React.lazy(() => import("../pages/TelecollerDashboardScreen"))
);

const FieldExecutiveDashboard = Loadable(
  React.lazy(() => import("../pages/FieldExecutiveDashboardScreen"))
);

const LoginScreen = Loadable(React.lazy(() => import("../pages/LoginScreen")));

const RagisterScreen = Loadable(
  React.lazy(() => import("../pages/LoginScreen/Ragister"))
);

const UserListScreen = Loadable(
  React.lazy(() => import("../pages/UserListScreen"))
);

const LeadListingScreen = Loadable(
  React.lazy(() => import("../pages/LeadListComponent"))
);

const LeadDetailsScreen = Loadable(
  React.lazy(() => import("../pages/LeadListComponent/Details"))
);

const UploadDataScreen = Loadable(
  React.lazy(() => import("../pages/UploadDataScreen"))
);

const AppointmentScreen = Loadable(
  React.lazy(() => import("../pages/AppointmentScreen"))
);

const RoleListScreen = Loadable(
  React.lazy(() => import("../pages/RoleListScreen"))
);

const GreetingCategoryScreen = Loadable(
  React.lazy(() => import("../pages/GreetingCategoryScreen"))
);

const GreetingScreen = Loadable(
  React.lazy(() => import("../pages/GreetingScreen"))
);

const LocationScreen = Loadable(
  React.lazy(() => import("../pages/LocationScreen"))
);

const AgentOnboardScreen = Loadable(
  React.lazy(() => import("../pages/AgentOnboardScreen"))
);

const AgentListScreen = Loadable(
  React.lazy(() => import("../pages/AgentListScreen"))
);

const MeetingListScreen = Loadable(
  React.lazy(() => import("../pages/MeetingListScreen"))
);

const ChannelScreen = Loadable(
  React.lazy(() => import("../pages/ChannelScreen"))
);

const BranchMasterScreen = Loadable(
  React.lazy(() => import("../pages/BranchMasterScreen"))
);

const DealerListScreen = Loadable(
  React.lazy(() => import("../pages/DealerListScreen"))
);

const ProductScreen = Loadable(
  React.lazy(() => import("../pages/ProductScreen"))
);

const SubscriptionPlanScreen = Loadable(
  React.lazy(() => import("../pages/SubscriptionPlanScreen"))
);

const SubscriptionBenefitScreen = Loadable(
  React.lazy(() => import("../pages/SubscriptionBenefitScreen"))
);

const SubscriptionMappingScreen = Loadable(
  React.lazy(() => import("../pages/SubscriptionMappingScreen"))
);

const AddDealer = Loadable(
  React.lazy(() => import("../components/pages/DealerListComponent/AddDealer"))
);

const AddGlobalDealer = Loadable(
  React.lazy(() =>
    import("../components/pages/DealerListGlobalComponent/AddDealer")
  )
);

const EditDealer = Loadable(
  React.lazy(() => import("../components/pages/DealerListComponent/EditDealer"))
);

const AddDealerProductDetails = Loadable(
  React.lazy(() =>
    import(
      "../components/pages/DealerListComponent/AddDealer/AddDealerProductDetails"
    )
  )
);

const EditDealerProductDetails = Loadable(
  React.lazy(() =>
    import(
      "../components/pages/DealerListComponent/EditDealer/EditDealerProductDetails"
    )
  )
);

const CampaigList = Loadable(
  React.lazy(() => import("../components/pages/CampaigListComponent"))
);

const FeedbackList = Loadable(
  React.lazy(() => import("../components/pages/FeedbackComponent/FeedbackList"))
);

// const AddDealerSubscriptionsDetails = Loadable(
//   React.lazy(() =>
//     import(
//       "../components/pages/DealerListComponent/AddDealer/AddDealerSubscriptions"
//     )
//   )
// );

const AddDealerShipping = Loadable(
  React.lazy(() =>
    import(
      "../components/pages/DealerListComponent/AddDealer/AddDealerShipping"
    )
  )
);

const RouteComponent = () => {
  const { isAuthenticated, role, role_id } = useAuth();
  const [dashboardComponent, setDashboardComponent] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    // Redirect to login page if not authenticated
    if (!isAuthenticated) {
      navigate(ROUTES_NAVIGATION.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    // Determine dashboard component based on user's role
    if (isAuthenticated && role_id) {
      switch (role_id) {
        case "1":
          setDashboardComponent(<SuperAdminDashboard />);
          break;
        case "2":
          setDashboardComponent(<AdminDashboard />);
          break;
        case "3":
          setDashboardComponent(<ManagerDashboard />);
          break;
        case "4":
          setDashboardComponent(<TelecollerDashboard />);
          break;
        case "5":
          setDashboardComponent(<FieldExecutiveDashboard />);
          break;
        default:
          setDashboardComponent(null); // Handle unknown roles here
      }
    }
  }, [isAuthenticated, role_id]);

  // Render LoginScreen component only when not authenticated
  if (!isAuthenticated) {
    return <LoginScreen />;
  }

  return (
    <Routes>
      <Route path={ROUTES_NAVIGATION.DASHBOARD} element={<MainLayout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route index element={dashboardComponent} />
        <Route
          path={ROUTES_NAVIGATION.USER_LIST}
          element={<UserListScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.USER_LIST}
          element={<UserListScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.LEADLISTING}
          element={<LeadListingScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.LEADDETAILS}
          element={<LeadDetailsScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.UPLOAD_DATA}
          element={<UploadDataScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.APPOINTMENT}
          element={<AppointmentScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.ROLE_LIST}
          element={<RoleListScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.GREETING_CATEGORY_LIST}
          element={<GreetingCategoryScreen />}
        />
        <Route path={ROUTES_NAVIGATION.GREETING} element={<GreetingScreen />} />
        <Route path={ROUTES_NAVIGATION.LOCATION} element={<LocationScreen />} />
        <Route path={ROUTES_NAVIGATION.CHANNEL} element={<ChannelScreen />} />
        <Route
          path={ROUTES_NAVIGATION.BRANCH_MASTER}
          element={<BranchMasterScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.AGENTONBOARD}
          element={<AgentOnboardScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.AGENTLIST}
          element={<AgentListScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.MEETINGLIST}
          element={<MeetingListScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.DEALER_LIST}
          element={<DealerListScreen />}
        />
        {/* ADD_DEALER Login */}
        <Route path={ROUTES_NAVIGATION.ADD_DEALER} element={<AddDealer />} />
        {/* DEALER_ADD_PAGE */}

        {/* Global Add Dealer */}
        <Route
          path={ROUTES_NAVIGATION.DEALER_ADD_PAGE}
          element={<AddGlobalDealer />}
        />
        <Route path={ROUTES_NAVIGATION.Edit_DEALER} element={<EditDealer />} />
        <Route
          path={ROUTES_NAVIGATION.DEALER_PRODUCT}
          element={<AddDealerProductDetails />}
        />
        <Route
          path={ROUTES_NAVIGATION.Edit_DEALER_PRODUCT}
          element={<EditDealerProductDetails />}
        />
        {/* <Route
          path={ROUTES_NAVIGATION.DEALER_SUBSCRIPTIONS}
          element={<AddDealerSubscriptionsDetails />}
        /> */}
        <Route
          path={ROUTES_NAVIGATION.DEALER_SHIPPING}
          element={<AddDealerShipping />}
        />
        <Route path={ROUTES_NAVIGATION.PRODUCTS} element={<ProductScreen />} />
        <Route
          path={ROUTES_NAVIGATION.SUBSCRIPTION_PLAN}
          element={<SubscriptionPlanScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.SUBSCRIPTION_BENEFIT}
          element={<SubscriptionBenefitScreen />}
        />
        <Route
          path={ROUTES_NAVIGATION.SUBSCRIPTION_MAPPING}
          element={<SubscriptionMappingScreen />}
        />
        {/* <Route path={ROUTES_NAVIGATION.LOGIN} element={<LoginScreen />} /> */}
        <Route
          path={ROUTES_NAVIGATION.CAMPAIGN_LIST}
          element={<CampaigList />}
        />
        {/* <Route path={ROUTES_NAVIGATION.FEEDBACK_LIST} element={<FeedbackList />} />   */}
      </Route>

      <Route
        path={ROUTES_NAVIGATION.DEALER_LIST}
        element={<DealerListScreen />}
      />
      {/* <Route path={ROUTES_NAVIGATION.ADD_DEALER} element={<AddDealer />} /> */}
      <Route path={ROUTES_NAVIGATION.Edit_DEALER} element={<EditDealer />} />

      <Route
        path={ROUTES_NAVIGATION.DEALER_PRODUCT}
        element={<AddDealerProductDetails />}
      />

      <Route
        path={ROUTES_NAVIGATION.Edit_DEALER_PRODUCT}
        element={<EditDealerProductDetails />}
      />
      <Route
        path={ROUTES_NAVIGATION.DEALER_SHIPPING}
        element={<AddDealerShipping />}
      />

      {/* No need for wildcard route */}
    </Routes>
  );
};

export default RouteComponent;
