export const APIUrlStrings = {
  login: "/login",
  logout: "/logout",
  roles: "/roles",
  userRoleslist: "/get-assign-user-role",
  roleslist: "/get-roles-list",
  sideBarMenuList: "/get-menu-master-list",
  createRole: "/create-update-role",
  editRole: "/edit-role",
  deleteRole: "/delete-roles",
  userlist: "/get-users-list",
  useradd: "/add-user-list",
  userEdit: "/edit-user-list",
  userUpdate: "/update-user-list",
  agentonboardlist: "/get-agent-onboard-list",
  locationList: "/location-list",
  getStateList: "/get-state-list",
  getStateByCity: "/state-by-city",
  getTeamvisit: "/get-team-visit",
  getChannellist: "/get-channel-list",
  addupdateChannel: "/create-update-channel",
  editChannel: "/edit-channel",
  deleteChannel: "/delete-channel",
  branchList: "/get-branch-list",
  createBranchList: "/create-branch-list",
  editBranchList: "/edit-branch-list",
  updateBranchList: "/update-branch-list",

  addLocation: "/add-location-list",
  editLocation: "/edit-location-list",
  updateLocation: "/update-location-list",

  addupdateGreetingcategory: "/create-update-greeting-category",
  getGreetingcategorylist: "/greeting-category-list",
  editGreetingcategory: "/edit-greeting-category",

  getmastergreetinglist: "/get-greeting-list",
  addupdategreetingmaster: "/create-update-greeting",
  editmastergreeting: "/edit-greeting",
  deletemastergreeting: "/delete-greeting",
  getgreetingmastertype: "/get-greeting-type",

  getSubcriptionPlan: "/get-subscription-plan",

  leadList: "/get-lead-list",
  leadAdd: "/get-greeting-type",
  reassignLead: "/reassign-lead",
  leadDetails: "/get-lead-details",
  reassignLeadUser: "/get-reassign-lead-data",
  downloadLeadData: "/download-lead-data",
  leadStatusList: "/lead-status-list",
  leadDelete: "/delete-lead",
  leadSource: "/get-lead-source",
  leadTypes: "/get-lead-types",
  ReassignBack: "/reassign-back-lead",
  createLead: "/create-lead",
  salesExecutiveUsers: "/get-sales-executive-users",
  vehicleTypes: "/get-vehicle-types",
  updateStatusLead: "/update-status-reassign-lead",
  EditLead: "/edit-lead-list",
  UpdateLead: "/update-lead",
  updateBookService: "/update-book-service",
  assignLeads: "/assign-leads",
  userassignlist: "/get-admin-user-assign-list",
  getRoles: "/get-role-based-users",
  deleteuserlist: "/delete-user-list",
  permissions: "/permissions",
  createPermission: "/create-permission",
  createRolesPermission: "/role-permission",
  roleWisePermission: "/role-wise-permission",
  deletePermission: "/delete-permission",
  updatePermission: "/update-permission",
  pincodes: "/pincodes",
  createPincode: "/create-pincode",
  deletePincode: "/delete-pincode",
  updatePincode: "/update-pincode",
  timeSlots: "/time-slot-list",
  deliveryTimeSlotDayWise: "/delivery-time-slot-day-wise",
  createTimeSlot: "/create-time-slot",
  deleteTimeSlot: "/delete-time-slot",
  updateTimeSlot: "/update-time-slot",
  TimeSlotedit: "/edit-time-slot",
  deliveryTypes: "/delivery-types",
  customerList: "/customer-list",
  customerAddressList: "/add-customer-address",
  createCustomerAddress: "/add-customer-address",
  addToCartProduct: "/add-to-cart",
  cartProductList: "/cart-items",
  removeCartProduct: "/remove-cart-product",
  cartProductQtyIncrement: "/cart-qty-increment",
  cartProductQtyDecrement: "/cart-qty-decrement",
  createDeliveryType: "/create-delivery-types",
  deleteDeliveryType: "/delete-delivery-types",
  updateDeliveryType: "/update-delivery-types",
  mainStoreList: "/main-store-list",
  createMainStore: "/create-main-store",
  deleteMainStore: "/delete-main-store",
  updateMainStore: "/update-main-store",
  subStoreList: "/sub-store-list",
  subStoreEditList: "/edit-sub-store",
  subStoreIsSubStoresList: "/is-sub-store-pincode-list",
  createSubStore: "/create-sub-store",
  deleteSubStore: "/delete-sub-store",
  updateSubStore: "/update-sub-store",
  allCategoryList: "/all-categories",
  mainCategoryList: "/category-list",
  editCategoryList: "/edit-category",
  subCategoryList: "/sub-category-list",
  statusUpdateCategory: "/status-update",
  createCategory: "/create-category",
  deleteCategory: "/delete-category",
  updateCategory: "/update-category",
  collectionsList: "/collection-list",
  createCollection: "/create-collection",
  deleteCollection: "/collection-delete",
  updateCollection: "/update-collection",
  editCollection: "/edit-collection",
  statusUpdateCollection: "/collection-status-update",
  usersList: "/user",
  createUsers: "/store",
  editUsers: "/user-edit",
  deleteUsers: "/delete-user",
  updateUsers: "/update-user",
  updateStatusUsers: "/user-status-update",
  brandsList: "/brand-list",
  createBrands: "/add-brand",
  editBrands: "/edit-brand",
  deleteBrands: "/brand-delete",
  updateBrands: "/update-brands",
  statusUpdateBrands: "/brand-status-update",
  bannerList: "/banner-list",
  editBanner: "/edit-banner",
  createBanner: "/create-banner",
  deleteBanner: "/banner-delete",
  updateBanner: "/banner-update",
  statusUpdateBanner: "/banner-status-update",
  pincodeWiseProducts: "/pincode-wise-products",
  editProduct: "/edit-product",
  createProduct: "/create-product",
  deleteProduct: "/product-delete",
  updateProduct: "/product-update",
  statusUpdateProduct: "/product-status-update",
  addonProduct: "/addon-product",
  orderList: "/orders-list",
  refund: "/refund-list",
  orderProducts: "/order-products",
  orderProductsOrderPlace: "/order-place",
  stateList: "/state-list",
  cityList: "/city-list",
  areaList: "/area-list",
  createCoupon: "/create-coupon",
  updateCoupon: "/update-coupon",
  couponCodeList: "/list-coupon",
  deleteCouponCode: "/delete-coupon",
  updateStatusCouponCode: "/update-coupon-status",
  editCouponCode: "/edit-coupon",
  shipmentList: "/shipment-list",
  shipmentCreate: "/create-shipment",
  shipmentDelete: "/delete-shipment",
  shipmentEdit: "/edit-shipment",
  shipmentUpdate: "/update-shipment",
  updateConfigration: "configuration-site-update",
  deleteConfigration: "configuration-site-delete",
  createConfiration: "configuration-site-create",
  editConfigration: "configuration-site-edit",
  listConfigration: "/configuration-site-list",
  ConfigrationGrouplist: "section-group-list",

  dealerList: "/get-dealer-list",
  addUpdateDealer: "add-update-dealer",
  // editDealer: "edit-dealer",
  AddDealer: "/dealer-on-boarding-submit",
  pincodeDealer: "/get-city-state-by-pincode",
  DealerType: "/get-dealer-type",
  EditDealer: "/edit-dealer-on-boarding",
  UpdateDealr: "/update-dealer-on-boarding",
  DownloadLeadSampleFile: "/download-lead-sample-file",
  UploadLeads: "/upload-leads",
  CreateRazerpay: "/create-razorpay-order",
  SuccessRazerpay: "/razorpay-payment-success",
  clickToCall: "/click-to-call",

  dashboardData: "/dashboard-data",
  filterOEMdashboardData: "/get-zones-state-by-dealer",

  productList: "/get-product-list",
  productAdd: "/create-update-product-data",
  productEdit: "/edit-product-list",
  subscriptionPlan: "/get-subscription-plan-list",
  createUpdateSubscriptionPlan: "/create-update-subscription-plan",
  editSubscriptionPlan: "/edit-subscription-plan",
  subscriptionBenefitList: "/get-subscription-benefit-list",
  createUpdateSubscriptionBenefit: "/create-update-subscription-benefit",
  editSubscriptionBenefit: "/edit-subscription-benefit",
  SubscriptionBenefitMapping: "/get-subscription-benefit-mapping-list",
  subscriptionPlanBenefitSeletionList: "/get-subscription-plan-benefit",
  editSubscriptionBenefitMapping: "/edit-subscription-benefit-mapping",
  createUpdateSubscriptionBenefitMapping:
    "/create-update-subscription-benefit-mapping",

  campaignRegister: "/campaign-register",
  campaignFeedback: "/campaign-feedback",
  campaignList: "/get-campaign",
  campaignFeedbackList: "/get-campaign-feedback",
  campaignMasterList: "/get-campaign-master-list",
};
