import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";

const RagisterTable = (props) => {
  const { userList = [], onEdit, onDelete } = props;
  const [filteredUsers, setFilteredUsers] = useState(userList);

  useEffect(() => {
    setFilteredUsers(userList);
  }, [userList]);

  const columns = [
    {
      name: "Campaign Name",
      selector: (row) => row.campaign_name,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobile_no,
    },
    {
      name: "Registration Date",
      selector: (row) => row.registion_date,
    },
  ];

  const tableheader = {
    headCells: {
      style: {
        fontWeight: "blod",
        fontSize: "15px",
        backgroundColor: "#F6924B",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredUsers}
        customStyles={tableheader}
        pagination
        selectableRowsHighlight
        highlightOnHover
        subHeaderAlign="left"
      />
    </>
  );
};

export default RagisterTable;
