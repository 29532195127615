import React from "react";
import { Route, Routes } from "react-router-dom";
import Loadable from "../components/shared/Loadable";
import ROUTES_NAVIGATION from "./routes";

const GlobalAddDealer = Loadable(
  React.lazy(() =>
    import("../components/pages/DealerListGlobalComponent/AddDealer")
  )
);

const GlobalAddDealerProductDetails = Loadable(
  React.lazy(() =>
    import(
      "../components/pages/DealerListGlobalComponent/AddDealer/AddDealerProductDetails"
    )
  )
);

const GlobalAddDealerShipping = Loadable(
  React.lazy(() =>
    import(
      "../components/pages/DealerListGlobalComponent/AddDealer/AddDealerShipping"
    )
  )
);

const DealerComponent = () => {
  return (
    <Routes>
      <Route path={ROUTES_NAVIGATION.DEALER_ADD_PAGE} element={<GlobalAddDealer />} />
      <Route
        path={ROUTES_NAVIGATION.DEALER_PRODUCT_PAGE}
        element={<GlobalAddDealerProductDetails />}
      />
      <Route
        path={ROUTES_NAVIGATION.DEALER_SHIPPING_PAGE}
        element={<GlobalAddDealerShipping />}
      />
    </Routes>
  );
};

export default DealerComponent;
